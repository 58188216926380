<template>
    <div>
      <Header />
      <router-view/>
      <Footer />
    </div>
</template>


<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
export default {
  name: "App",

  components: {
    Header,
    Footer,
  },
};
</script>
<style lang="scss">

.cursor-pointer {
 cursor: pointer;
}

body {
  background: var(--black-bg) !important;
}

#app {
  font-family: Unbounded, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --white-bg: #fff;
  --black-bg: #000;
  --white: #fff;
  --black: #000;
  --pink: #ce1db4;
  --purple: #6602ff;
  --gradient-top: linear-gradient(#000, rgba(0, 0, 0, 0));
  --gradient-sides: linear-gradient(
    to right,
    #000,
    rgb(0 0 0 / 96%) 8%,
    rgb(0 0 0 / 53%) 15%,
    rgba(0, 0, 0, 0) 85%,
    rgb(0 0 0) 92%,
    #000
  );
  --gradien-color-1: #6602ff;
  --gradien-color-2: #ce1db4;
  --grey: #797c86;
  --border: #303236;
}


@font-face {
  font-family: Unbounded;
  src: url(./assets/fonts/Unbounded-Regular.ttf);
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: Unbounded;
  src: url(./assets/fonts/Unbounded-Medium.ttf);
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: Unbounded;
  src: url(./assets/fonts/Unbounded-ExtraBold.ttf);
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: Unbounded;
  src: url(./assets/fonts/Unbounded-Bold.ttf);
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  src: url(./assets/fonts/Poppins-Regular.ttf);
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  src: url(./assets/fonts/Poppins-Medium.ttf);
  font-display: swap;
}

</style>
