<template>
  <div>
      <section>
          <div class="container">
              <div class="row align-items-center gap-4">
                  <div class="col-lg-6">
                      <div class="img__wrapp position-relative h-100">
                          <img src="../assets/images/hybridexchange/hero.png" alt="" width="100%" height="700"
                              class="object-fit-cover">
                      </div>
                  </div>
                  <div class="col-lg-5">
                      <div class="main-left">
                          <div class="text-white fs-6 mb-3">Powered by
                              <img src="../assets/logo.png" alt="" width="60">
                          </div>
                          <div class="d-flex gap-4 flex-column mb-3">
                              <h2 class="text-white main_text">Trading, decentralized</h2>
                              <h5 class="text-white fs-6 fw-normal lh-base">
                                  GDCC is the leading DeFi protocol developer for advanced trading. Trade 57
                                  cryptocurrencies with low fees, deep liquidity, and up to 20× Buying Power.
                              </h5>
                          </div>
                          <div class="btn__group  mb-3 d-flex flex-column gap-3 w-50">
                              <div class="d-flex gap-4">
                                  <button class="new__btn btn btn-primary d-flex align-items-center gap-3 w-100 justify-content-center">
                                      Trade
                                  </button>
                                  <button class="new__btn btn btn-outline-secondary d-flex align-items-center gap-3 w-100 justify-content-center">
                                      Trade
                                  </button>
                              </div>
                              <div class="d-flex flex-column gap-3">
                                  <button
                                      class="new__btn btn btn-outline-secondary d-flex align-items-center justify-content-center gap-4 px-3 py-2 w-100">
                                      <span>
                                          <img src="../assets/images/icon/apple.svg" alt="">
                                      </span> Get GDCC for iphone
                                  </button>
                                  <button
                                      class="new__btn btn btn-outline-secondary d-flex align-items-center justify-content-center gap-4 px-3 py-2 w-100">
                                      <span>
                                          <img src="../assets/images/icon/android.svg" alt="">
                                      </span> Get GDCC for android
                                  </button>
                              </div>
                              <div class="bottom__text d-flex gap-3 flex-column">
                                  <!-- <p class="m-0 new__btn  grey__text">Android coming soon.</p> -->
                                  <div class="new__btn">
                                      <p class="m-0 grey__text">GDCC does not operate, run or deploy any
                                          infrastructure on GDCC Chain. <a href="/"
                                              class="text-white text-decoration-none">Learn more →</a></p>

                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
      <section>
          <div class="container">
              <div class="row">
                  <div class="col-lg-3" v-for="(item, index) in counters" :key="index">
                      <div class="text-white text-center">
                          <h5 class="grey__text new__btn fw-normal">{{ item.para }}</h5>

                          <!-- {{item.head}} {{ index}} -->
                          <vue3-autocounter ref='counter' class="fs-1" :startAmount='0' :endAmount='item.head'
                              :prefix='item.prefix' :duration='6' separator=',' decimalSeparator='.'
                              :autoinit='true' />
                      </div>
                  </div>
              </div>
          </div>
      </section>
      <section>
          <div class="container">
              <div class="row row-gap-4">
                  <div class="col-lg-12">
                      <div class="section-heading">
                          <h2 class="text-white">Trading, the way it should be</h2>
                          <p class="text-white grey__text">The best of DeFi meets a professional trading experience
                          </p>
                      </div>
                  </div>
                  <div class="col-lg-4 Features__Grid " v-for="(feature, index) in features" :key="index">
                      <div class="Features__Wrapper d-flex gap-3 align-items-start">
                          <div class="Features__Shrink">
                              <img :src="require('../assets/images/hybridexchange/' + feature.imageSrc )" alt=""
                                  class="Features___StyledImg" width="40" />
                          </div>
                          <div class="Features__Left">
                              <dt class="Features__FeatureTitle text-white fs-5 mb-2">{{ feature.title }}</dt>
                              <dd class="Features__FeatureDescription grey__text fs-7">{{ feature.description }}</dd>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>

      <section>
          <div class="container">
              <div class="row row-gap-4">
                  <div class="col-lg-12">
                      <div class="section-heading">
                          <h2 class="text-white">Market</h2>
                          <p class="text-white grey__text">We are continuously launching new Perpetual Contract
                              markets.</p>
                      </div>
                  </div>
                  <div class="col-lg-12">
                      <div class="market_stats">
                          <table class="table table-dark table-striped table-hover">
                              <thead>
                                  <tr>
                                      <th scope="col" class="p-3">#</th>
                                      <th scope="col" class="p-3">Name</th>
                                      <th scope="col" class="p-3">Price</th>
                                      <th scope="col" class="p-3">CHG</th>
                                      <th scope="col" class="p-3">Vol</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr v-for="(item, index) in market" :key="index">
                                      <td class="p-3" valign="middle">{{ index + 1 }}</td>
                                      <td class="p-3">
                                          <div class="d-flex align-items-center gap-2">
                                              <img :src="require('../assets/images/hybridexchange/' + item.imageSrc )"
                                                  alt="" width="35" class="rounded-circle">
                                              <p class="m-0">{{ item.name }} <span class="grey__text new__btn">{{
                                                      item.symbol }}</span></p>
                                          </div>
                                      </td>
                                      <td class="p-3 fs-7">{{ item.price }}</td>
                                      <td class="p-3 fs-7"
                                          :class="[item.chg.startsWith('-') ? 'text-danger' : 'text-success', getUpDownClass(item.chg)]">
                                          {{ item.chg }}</td>
                                      <td class="p-3 fs-7">{{ item.vol }}</td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                  </div>
              </div>
          </div>
      </section>

      <section>
          <div class="container">
              <div class="row row-gap-4">
                  <div class="col-lg-12">
                      <div class="section-heading">
                          <h2 class="text-white">Features</h2>
                          <p class="text-white grey__text">We have rebuilt our exchange from the ground up.</p>
                      </div>
                  </div>
                  <div class="col-lg-12">
                      <div class="row">
                          <div class="col-lg-7 position-relative">
                              <div class="new__feature d-flex rounded-4 gap-3 position-relative overflow-hidden">
                                  <div class="d-flex flex-column">
                                      <div class="mobile">
                                          <h4 class="text-white">Mobile friendly</h4>
                                          <p class="grey__text new__btn">A responsive web interface, and native applications
                                              designed from the ground up</p>
                                      </div>
                                      <div class="d-flex flex-column gap-3">
                                         <button
                                          class="new__btn btn btn-outline-secondary d-flex align-items-center gap-4 justify-content-center px-3 py-2 w-100">
                                          <span>
                                              <img src="../assets/images/icon/apple.svg" alt="">
                                          </span> Get GDCC for iphone 
                                      </button>
                                      <button
                                      class="new__btn btn btn-outline-secondary d-flex align-items-center gap-4 justify-content-center px-3 py-2 w-100">
                                        <span>
                                            <img src="../assets/images/icon/android.svg" alt="">
                                        </span> Get GDCC for android
                                    </button>
                                      </div>
                                     
                                  </div>
                                  <div class="position-relative">
                                      <img src="../assets/images/hybridexchange/mobile.png" alt="" width="100%">
                                  </div>
                              </div>
                          </div>
                          <div class="col-lg-5">
                              <div class="row h-100 row-gap-4">
                                  <div class="col-lg-12">
                                      <div class="new__feature rounded-4 h-100 d-flex gap-3 align-items-center">
                                          <div class="d-flex flex-column gap-3">
                                              <div class="mobile d-flex flex-column gap-3">
                                                  <h4 class="text-white m-0">Fully decentralized</h4>
                                                  <p class="grey__text new__btn m-0">A native chain and governance protocol for GDCC</p>
                                              </div>
                                              <div class="d-flex gap-3 my-3">
                                                  <button class="new__btn btn btn-primary d-flex align-items-center gap-3">
                                                     <img src="../assets/images/hybridexchange/dashboard.svg" alt=""> Dashboard
                                                  </button>
                                                  <button class="new__btn btn btn-outline-secondary d-flex align-items-center gap-3">
                                                     <img src="../assets/images/hybridexchange/forums.svg" alt=""> Forums
                                                  </button>
                                              </div>
                                              <div class="explore_link">
                                                  <span class="grey__text new__btn">View block explorer Curious to explore? <span class="text-white">here  →</span></span>
                                              </div>
                                          </div>
                                          <div>
                                              <img src="../assets/images/hybridexchange/decentralized.svg" alt="" width="100%">
                                          </div>
                                      </div>
                                  </div>
                                  <div class="col-lg-12">
                                      <div class="new__feature rounded-4 h-100 d-flex gap-3 align-items-center">
                                          <div>
                                              <img src="../assets/images/hybridexchange/help-center.svg" alt="" width="100%">
                                          </div>
                                          <div class="d-flex flex-column gap-3">
                                              <div class="mobile d-flex flex-column gap-3">
                                                  <h4 class="text-white m-0">Help Center</h4>
                                                  <p class="grey__text new__btn m-0">Learn about trading on GDCC from the help center</p>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-12">
                      <div class="row h-100">
                          <div class="col-lg-5">
                              <div class="row h-100 row-gap-4">
                                  <div class="col-lg-12">
                                      <div class="new__feature rounded-4 h-100 d-flex gap-3 align-items-center">
                                          <div class="d-flex flex-column gap-3">
                                              <div class="mobile d-flex flex-column gap-3">
                                                  <h4 class="text-white m-0">Self custody</h4>
                                                  <p class="text-white new__btn m-0">Your funds are yours. Trade with no risk of bank runs.</p>
                                              </div>
                                             
                                          </div>
                                          <div>
                                              <img src="../assets/images/hybridexchange/wallet.png" alt="" width="100%">
                                          </div>
                                      </div>
                                  </div>
                                  <div class="col-lg-12">
                                      <div class="new__feature rounded-4 h-100 d-flex gap-3 align-items-center">
                                          <div class="d-flex flex-column gap-3">
                                              <div class="mobile d-flex flex-column gap-3">
                                                  <h4 class="text-white m-0">Simple onboarding</h4>
                                                  <p class="text-white new__btn m-0">Get started with GDCC Chain in seconds, with your existing crypto wallet.</p>
                                              </div>
                                              <div class="d-flex gap-3">
                                                  <button class="new__btn btn btn-outline-secondary d-flex align-items-center gap-3 justify-content-center">
                                                     Get started
                                                  </button>
                                              </div>
                                              <div class="explore_link">
                                                  <span class="grey__text new__btn">You can use various frontends to access GDCC <span class="text-white">Learn more  →</span></span>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>

                          <div class="col-lg-7 position-relative h-100">
                              <div class="new__feature d-flex rounded-4 gap-3 position-relative overflow-hidden h-100">
                                  <div class="d-flex flex-column">
                                      <div class="mobile">
                                          <h4 class="text-white">Advanced order types</h4>
                                          <p class="new__btn grey__text">Trade like a pro with advanced order types, including price triggers.</p>
                                      </div>
                                      <button class="btn__feature_trade new__btn btn btn-primary d-flex align-items-center justify-content-center">
                                          Trade
                                      </button>
                                  </div>
                                  <div class="position-relative">
                                      <img src="../assets/images/hybridexchange/bracket-order.png" alt="" width="100%" height="100%">
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-12">
                      <div class="row">
                          <div class="col-lg-6">
                              <div class="new__feature rounded-4 h-100 d-flex gap-3 align-items-center">
                                  <div class="mobile">
                                      <h4 class="text-white">Earn trading rewards</h4>
                                      <p class="text-white new__btn">Earn rewards in GDCC, as you trade. With GDCC you can contribute to the governance and future of the protocol.</p>
                                      <p class="grey__text new__btn">All rewards are made available by the gdcc Foundation. gdcc Trading Inc. is not affiliated with the aforementioned rewards program.</p>
                                  </div>
                              </div>
                          </div>
                          <div class="col-lg-6">
                              <div class="new__feature rounded-4 h-100 d-flex gap-3 align-items-center">
                                  <div class="mobile">
                                      <h4 class="text-white">Fully open source</h4>
                                      <p class="grey__text new__btn">Build on top of the GDCC protocol. Get started by reading documentation, or view the code on Github</p>
                                      <button class="new__btn btn btn-outline-secondary d-flex align-items-center gap-3 justify-content-center">
                                          Get started
                                       </button>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
  </div>
</template>


<script>
  import { defineComponent } from 'vue';
  import Vue3autocounter from 'vue3-autocounter';

  export default defineComponent({
      name: 'HybridExchange',
      components: {
          'vue3-autocounter': Vue3autocounter
      },

      data() {
          return {
              market: [
                  {
                      name: 'BNB',
                      symbol: 'BNB-USD',
                      imageSrc: 'market1.png',
                      price: '$69,902.49',
                      chg: '-12.15%',
                      vol: '$19.87M'
                  },
                  {
                      name: 'Ripple',
                      symbol: 'XRP-USD',
                      imageSrc: 'xrp.png',
                      price: '$0.0582',
                      chg: '6.75%',
                      vol: '$2,827,544,624'
                  },
                  {
                      name: 'SUI',
                      symbol: 'SUI-USD',
                      imageSrc: 'sui.png',
                      price: '$2.7',
                      chg: '-12.15%',
                      vol: '$542.56M'
                  },
                  {
                      name: 'Solana',
                      symbol: 'Sol-USD',
                      imageSrc: 'sol.png',
                      price: '$180',
                      chg: '4.15%',
                      vol: '$23M'
                  },
              ],
              counters: [
                  {
                      para: 'Markets',
                      head: 59,
                      prefix: '$',
                  },
                  {
                      para: "Today's volume",
                      head: 1234,
                      prefix: '$',
                  },
                  {
                      para: "Today's trades",
                      head: 886026,
                  },
                  {
                      para: 'Open Interest',
                      head: 1606149,
                      prefix: '$',
                  },
              ],
              features: [
                  {
                      "title": "Self custody",
                      "imageSrc": "feature1.svg",
                      "description": "You, and only you, keep custody of your funds while trading"
                  },
                  {
                      "title": "Many markets",
                      "imageSrc": "feature2.svg",
                      "description": "Trade top pairs with deep liquidity. New pairs listed frequently."
                  },
                  {
                      "title": "Rewards",
                      "imageSrc": "feature3.svg",
                      "description": "Instantly earn $GDCC after each trade"
                  },
                  {
                      "title": "Fast onboarding",
                      "imageSrc": "feature4.svg",
                      "description": "Log in with your existing crypto wallet and start trading in minutes"
                  },
                  {
                      "title": "Governance",
                      "imageSrc": "feature5.svg",
                      "description": "You, the trader, control the future of GDCC Chain"
                  },
                  {
                      "title": "Low fees",
                      "imageSrc": "feature6.svg",
                      "description": "Trade in size with an attractive fee structure"
                  }
              ],

          };
      },

      methods: {
          getUpDownClass(chg) {
              return chg.startsWith('-') ? 'text-danger' : 'text-success';
          }
      }

  }); 
</script>

<style scoped>
  .main_text {
      font-size: 52px;
  }

  .main_text h5 {
      letter-spacing: 0.95px;

  }

  :is(.btn:active, .btn-primary) {
      background-color: #ce1db4;
      border-color: #ce1db4;
      padding: 10px 16px;
  }

  .btn-outline-secondary {
      border-color: #FFF;
      padding: 10px 16px;
      color: #FFF;
  }

  .cst_badge {
      background-color: #ffffff59;
      font-size: 11px;
      padding: 6px;
      width: 24px;
      height: 24px;
  }

  .new__btn {
      font-size: 12px;
  }

  .bottom__text a:hover {
      text-underline-offset: 5px;
      text-decoration: underline !important;
  }

  .grey__text {
      color: #807e98;
  }

  .fs-7 {
      font-size: 14px;
  }

  .img__wrapp img {
      object-position: right;
  }

  .img__wrapp::before {
      content: "";
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0px;
      width: 4rem;
      --background: #101018;
      background-image: linear-gradient(to left, rgb(26 26 44 / 40%) 0%, var(--background) 80%);
  }

  .new__feature {
      background: #1e293b;
      padding: 32px 20px;
  }

  .btn__feature_trade{
      max-width: 120px;
  }

  section{
    padding: 70px 0;
  }
</style>